import React, { useState } from "react";
import { Tooltip } from "./Tooltip";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faBriefcase, faFileAlt, faImages, faQuoteLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header = ({ classicHeader, darkTheme, homeRef, handleNavClick }) => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg navbar-dark bg-dark border-bottom-0">
        <div className="container-fluid position-relative h-100 flex-lg-column ps-3 px-lg-3 pt-lg-3 pb-lg-2">
          {/* Logo */}
          <Link
            smooth
            duration={500}
            style={{ cursor: "pointer" }}
            to="home"
            className="mb-lg-auto mt-lg-4"
            onClick={(e) => {
              e.preventDefault();
              setIsNavModalClose(true);
            }}
          >
            <h1 className="text-5 text-white text-center mt-4 mb-0 d-lg-block">
              João Victor dos Santos Redondo
            </h1>
          </Link>
          {/* Logo End */}
          <div
            id="header-nav"
            className={
              isNavModalClose
                ? "collapse navbar-collapse w-100 my-lg-auto "
                : "show navbar-collapse w-100 my-lg-auto"
            }
          >
            <ul className="navbar-nav text-lg-start my-lg-auto py-lg-3 ms-2">
              <li className="nav-item">
                <Link
                  target={homeRef}
                  className="nav-link"
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="home"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  <FontAwesomeIcon icon={faHome} style={{ marginRight: '8px' }} />
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="about"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
                  Sobre Mim
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="services"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  <FontAwesomeIcon icon={faBriefcase} style={{ marginRight: '8px' }} />
                  O que eu faço?
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="resume"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '8px' }} />
                  Resumo
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="portfolio"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  <FontAwesomeIcon icon={faImages} style={{ marginRight: '8px' }} />
                  Portfólio
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="testimonial"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  <FontAwesomeIcon icon={faQuoteLeft} style={{ marginRight: '8px' }} />
                  Avaliações
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="contact"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />
                  Contato
                </Link>
              </li>
            </ul>
          </div>
          <ul className="social-icons social-icons-muted social-icons-sm mt-lg-auto ms-auto ms-lg-0 d-flex mb-4 ">
            <li className="social-icons-instagram">
              <Tooltip text="Instagram" placement="top">
                <a
                  href="https://www.instagram.com/jvredondo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram text-danger custom-icon-size" />
                </a>
              </Tooltip>
            </li>
            <li className="social-icons-linkedin">
              <Tooltip text="Linkedin" placement="top">
                <a
                  href="https://www.linkedin.com/in/joaovictorredondo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin-in text-info custom-icon-size" />
                </a>
              </Tooltip>
            </li>
          </ul>
          <button
            onClick={(e) => {
              setIsNavModalClose(!isNavModalClose);
            }}
            className={
              isNavModalClose ? "navbar-toggler" : "navbar-toggler show"
            }
            id="navbar-toggler"
            type="button"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default Header;
