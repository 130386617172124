import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Desenvolvimento Web",
      desc: "Criação de websites dinâmicos e responsivos utilizando as melhores práticas e tecnologias atuais. Especializado em Laravel para backend e integração com diversas APIs.",
      icon: "fas fa-laptop-code",
    },
    {
      name: "Desenvolvimento Frontend",
      desc: "Desenvolvimento de interfaces de usuário interativas e atraentes com tecnologias como JavaScript, React, e jQuery. Foco em experiências de usuário fluidas e modernas.",
      icon: "fas fa-desktop",
    },
    {
      name: "Design de UI/UX",
      desc: "Criação de designs intuitivos e eficazes que melhoram a experiência do usuário. Utilizo ferramentas e metodologias para garantir uma interface visualmente agradável e funcional.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "Integração de APIs",
      desc: "Desenvolvimento e integração de APIs para conectar seu site ou aplicativo com serviços externos, garantindo uma comunicação fluida entre sistemas e otimização de funcionalidades.",
      icon: "fas fa-plug",
    },
    {
      name: "Consultoria em Desenvolvimento Web",
      desc: "Análise e aconselhamento sobre melhores práticas em desenvolvimento web. Ajudo a otimizar seu projeto, melhorar a performance e garantir que as melhores tecnologias sejam utilizadas.",
      icon: "fas fa-chart-line",
    },
    {
      name: "Suporte e Manutenção",
      desc: "Ofereço suporte contínuo e manutenção para garantir que seu site ou aplicativo funcione sem problemas. Isso inclui atualizações, correção de bugs e melhorias de performance.",
      icon: "fas fa-tools",
    },
  ];

return (
  <section
    id="services"
    className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
  >
    <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
      {/* Heading */}
      <div className="position-relative d-flex text-center mb-5">
        <h2
          className={
            "text-24  text-uppercase fw-600 w-100 mb-0 " +
            (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
          }
        >
          Serviços
        </h2>
        <p
          className={
            "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
            (darkTheme ? "text-white" : "text-dark")
          }
        >
          O que eu faço?
          <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
        </p>
      </div>
      {/* Heading end*/}
      {/* content start */}
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="row">
            {services.length > 0 &&
              services.map((service, index) => (
                <div className="col-md-6" key={index}>
                  <div className="featured-box style-3 mb-5">
                    <div
                      className={
                        "featured-box-icon text-primary  shadow-sm rounded " +
                        (darkTheme ? "bg-dark-1" : "bg-white")
                      }
                    >
                      <i className={service.icon} />
                    </div>
                    <h3 className={darkTheme ? "text-primary" : ""}>
                      {service.name}
                    </h3>
                    <p
                      className={"mb-0 " + (darkTheme ? "text-white-50 text-justify me-5" : "")}
                    >
                      {service.desc}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* content end */}
    </div>
  </section>
);
};

export default Services;
