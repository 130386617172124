import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Termos e Políticas
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Fechar"
            />
          </div>
          <div className="modal-body p-4">
            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              Termos e Condições
            </h3>
            <p>
              Bem-vindo ao nosso site. Ao acessar e utilizar este site, você concorda em cumprir e estar vinculado aos seguintes termos e condições. Se você não concordar com estes termos, por favor, não utilize nosso site.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              1. Aceitação dos Termos
            </h5>
            <p>
              Ao acessar e utilizar nosso site, você aceita e concorda em cumprir estes termos e condições. Se você não concordar, por favor, não use nosso site.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              2. Alterações nos Termos
            </h5>
            <p>
              Podemos atualizar estes termos periodicamente. Seu uso contínuo do site após as alterações constitui sua aceitação dos novos termos.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              3. Uso do Site
            </h5>
            <p>
              Você concorda em utilizar o site apenas para fins legais e de maneira que não infrinja os direitos de terceiros.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              4. Propriedade Intelectual
            </h5>
            <p>
              Todo o conteúdo do site é de nossa propriedade ou de nossos licenciadores e é protegido por leis de propriedade intelectual. Você não pode usar, reproduzir ou distribuir qualquer conteúdo sem nossa permissão.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              5. Links para Sites de Terceiros
            </h5>
            <p>
              Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelo conteúdo ou práticas de privacidade desses sites.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              6. Limitação de Responsabilidade
            </h5>
            <p>
              Não somos responsáveis por quaisquer danos decorrentes do seu uso do site, incluindo danos indiretos, incidentais ou consequenciais.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              7. Indemnização
            </h5>
            <p>
              Você concorda em nos indenizar por quaisquer reivindicações, responsabilidades, danos, perdas, custos ou despesas decorrentes do seu uso do site ou da sua violação destes termos.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              8. Lei Aplicável
            </h5>
            <p>
              Estes termos são regidos pelas leis de [Seu Estado/País]. Quaisquer disputas serão resolvidas nos tribunais competentes de [Seu Estado/País].
            </p>
            
            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              Política de Privacidade
            </h3>
            <p>
              Esta Política de Privacidade descreve como coletamos, usamos e protegemos suas informações pessoais. Ao usar nosso site, você consente com as práticas descritas nesta política.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              1. Informações Coletadas
            </h5>
            <p>
              Coletamos informações pessoais que você fornece voluntariamente, como nome e e-mail. Também coletamos informações sobre como você usa o site.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              2. Uso das Informações
            </h5>
            <p>
              Usamos suas informações para melhorar nossos serviços e comunicar-nos com você. Não compartilhamos suas informações com terceiros, exceto quando exigido por lei.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              3. Segurança
            </h5>
            <p>
              Implementamos medidas de segurança para proteger suas informações. No entanto, nenhuma transmissão de dados pela internet pode ser garantida como completamente segura.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              4. Cookies
            </h5>
            <p>
              Utilizamos cookies para melhorar sua experiência no site. Você pode ajustar as configurações do seu navegador para recusar cookies, mas isso pode afetar sua experiência.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              5. Seus Direitos
            </h5>
            <p>
              Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Entre em contato conosco para exercer esses direitos.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              6. Alterações na Política de Privacidade
            </h5>
            <p>
              Podemos atualizar esta Política de Privacidade periodicamente. Publicaremos quaisquer alterações no site e notificaremos você sobre atualizações significativas.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              7. Contato
            </h5>
            <p>
              Se você tiver alguma dúvida sobre estes termos ou nossas práticas de privacidade, entre em contato conosco pelo e-mail contato@joaovictorredondo.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
