import React from "react";
import resumeFile from "../documents/resume.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Sobre mim
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Sobre mim
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-7 me-4 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              Eu sou <span className="text-primary">João Victor dos Santos Redondo,</span> Software Engineer.
            </h2>
            <p className={darkTheme ? "text-white-50 text-justify" : ""}>
              Minha paixão por tecnologia começou cedo, quando eu tinha apenas 6 anos e tive meu primeiro contato com um computador. Desde então, a programação virou uma parte essencial da minha vida. Com 10 anos, comecei meu primeiro curso de informática e, desde então, não parei mais de aprender e explorar novas tecnologias.

              Com mais de 5 anos de experiência prática, me especializei em Laravel, uma ferramenta que uso para criar aplicações web robustas e eficientes. Além disso, trabalho com várias outras tecnologias que tornam os projetos mais dinâmicos e interativos, como JavaScript, AJAX, jQuery e React.
              Embora eu não tenha um diploma formal, busquei conhecimento através de cursos em PHP, HTML5, CSS3, JavaScript, MySQL e Python3. Esses cursos me ajudaram a construir uma base sólida e me proporcionaram as habilidades necessárias para resolver problemas complexos e criar soluções que atendam às necessidades dos meus clientes.
            </p>
            <p className={darkTheme ? "text-white-50 text-justify" : ""}>
              Meu objetivo é claro: desenvolver soluções que realmente façam a diferença para meus clientes. Estou aqui para ajudar a transformar suas ideias em realidade, entregando projetos que não só atendem, mas superam suas expectativas. Se você procura alguém comprometido e apaixonado por criar soluções tecnológicas eficazes, estou à disposição para conversar sobre como podemos trabalhar juntos.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul className={"list-style-2 " + (darkTheme ? "list-style-light text-light" : "")}>
                <li>
                  <i className="fas fa-user me-2"></i>
                  <span className="fw-600 me-2">Nome:</span>João Victor dos Santos Redondo
                </li>
                <li>
                  <i className="fas fa-envelope me-2"></i>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:contato@joaovictorredondo.com">contato@joaovictorredondo.com</a>
                </li>
                <li>
                  <i className="fas fa-calendar-alt me-2"></i>
                  <span className="fw-600 me-2">Idade:</span>22
                </li>
                <li className="border-0">
                  <i className="fas fa-map-marker-alt me-2"></i>
                  <span className="fw-600 me-2">País:</span>Cravinhos, São Paulo
                </li>
              </ul>            </div>
          </div>
        </div>
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>5</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Anos de Experiênica
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projetos Entregues
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
